(function($) {
    /** change value here to adjust parallax level */
    var parallax = -0.2;

    var $bg_images = $(".parallax-image");
    var offset_tops = [];
    $bg_images.each(function(i, el) {
        offset_tops.push($(el).offset().top);
    });

    var dy = $(this).scrollTop();
    $bg_images.each(function(i, el) {
        var ot = offset_tops[i];
        $(el).css("background-position", "50% calc(50% - "+(dy - ot) * parallax + "px"+")") ;
    });

    $(window).scroll(function() {
        var dy = $(this).scrollTop();
        $bg_images.each(function(i, el) {
            var ot = offset_tops[i];
            $(el).css("background-position", "50% calc(50% - "+(dy - ot) * parallax + "px"+")") ;
        });
    });
})(jQuery);
