/******************
 * Apply Button Sidebar Form Animation
 *****************/
document.addEventListener('click', function (event) {

    // If the clicked element doesn't have the right selector, bail
    if (event.target.matches('button.animate-sidebar-form')) {

        // Don't follow the link
        event.preventDefault();

        // Get form transition element
        let transition_form_el = document.getElementById('sidebar-career-form');

        // Get backdrop transition element
        let transition_backdrop_el = document.getElementById('bg-backdrop');

        // Animate element transition
        transition_form_el.classList.add('show');
        transition_backdrop_el.classList.add('show');

    }

    // If the clicked element doesn't have the right selector, bail
    if (event.target.matches('#bg-backdrop .bg')) {

        // Get form transition element
        let transition_form_el = document.getElementById('sidebar-career-form');

        // Get backdrop transition element
        let transition_backdrop_el = document.getElementById('bg-backdrop');

        // Animate element transition
        transition_form_el.classList.remove('show');
        transition_backdrop_el.classList.remove('show');

    }


    // If the clicked element doesn't have the right selector, bail
    if (event.target.matches('.careers-form-close-btn i')) {

        // Get form transition element
        let transition_form_el = document.getElementById('sidebar-career-form');

        // Get backdrop transition element
        let transition_backdrop_el = document.getElementById('bg-backdrop');

        // Animate element transition
        transition_form_el.classList.remove('show');
        transition_backdrop_el.classList.remove('show');

    }

}, false);
