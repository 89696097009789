/******************
 * Page Load Transition
 *****************/
document.addEventListener("DOMContentLoaded", function() {

    let transition_el = document.getElementById('page-transition');
    setTimeout( () => { transition_el.classList.add('reveal'); }, 600 );

});

/******************
 * Link transition
 *****************/
document.addEventListener('click', function (event) {

    // If the clicked element doesn't have the right selector, bail
    if (!event.target.matches('a.animate-page')) return;

    // Don't follow the link
    event.preventDefault();

    // Log the clicked element in the console
    console.log(event.target.href);

    // Get transition element
    let transition_el = document.getElementById('page-transition');

    // Animate page transition
    transition_el.classList.remove('reveal');

    // Redirect to link
    setTimeout( () => { window.location = event.target.href; }, 600 );

}, false);
