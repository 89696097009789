// Reveal animation on scroll (scrollreveal.js)
import ScrollReveal from "scrollreveal";

window.sr = ScrollReveal();
$('.reveal').each(function(){
    let delay = $(this).data('delay');
    let origin = $(this).data('origin');
    let top = $(this).data('top');
    let bottom = $(this).data('bottom');
    let distance = $(this).data('distance');
    let duration = $(this).data('duration');

    sr.reveal($(this),{
        duration   : duration,
        delay      : parseInt(delay),
        distance   : distance+'px',
        easing     : 'ease-out',
        origin     : origin,
        reset      : false,
        scale      : 1,
        viewFactor : 0,
        viewOffset: {
            top: top,
            bottom: bottom
        }
    }, 150);
});
